import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'yellow',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='bg-[#111639]  mb-5 rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/736x/a6/8f/07/a68f07803570e86948cd4039ea5133ac.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Moe Jeffery</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='px-2'>
            <p className='text-[whitesmoke] text-center pt-[13%] pb-5 '>I was scammed $72,000 by a fake investment site after years of sending them money.
               Then, I saw an ad about revoke. I reached out to them, and they effortlessly 
               recovered my funds. Thanks to this incredible team of experts, I regained my financial 
               security.</p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-3'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-3'>Atlanta, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#111639] rounded-md'>
          <div className='flex justify-center pt-5 mb-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/474x/35/9b/45/359b454539961cb1f212aeb4d3bdc391.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Seraphina Monroe</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className=''>
            <p className='text-[whitesmoke] text-center pt-5 pb-5'>
            My Facebook and Instagram accounts were disabled, leaving me frustrated. I fell victim to several scams by fake recovery firms, losing around $46,000. Fortunately, I was referred to Spyoptron. They not only reactivated my accounts but also successfully recovered the funds scammers took from me. 
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Idaho, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#111639] rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/CykNBWFW8AA2zFm?format=jpg&name=4096x4096' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Isolde Thorne</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='p-1'>
            <p className='text-[whitesmoke] text-center pt-2 pb-2'>I met a man on Facebook named Ryan Moore. We had been dating for years, and each month, I sent him $23,000 believing he was building a house for us in Arizona. It turned out he was lying and using my money for other purposes. When I confronted him, he blocked me on all social media. Then, I found Spyoptron, and they worked wonders. 
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[9%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Baltimore, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#111639] rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB5FkRBxsm-Vlqq37edRpUR1xyzzIBJHRJeQ&usqp=CAU'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Asher Atherton</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='py-1'>
            <p className='text-[whitesmoke] text-center pt-[5%] pb-5'>
            In my youth, I made a mistake, and my ex used compromising photos to blackmail me, putting my marriage at risk. I kept sending her money, desperate for relief. Then, I found Spyoptron. They not only recovered my money but also brought the blackmailer to justice. Thanks to this amazing team, I'm back on my feet.
               </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[10%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-[1%]'>Manitoba, Canada 🇨🇦</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#111639] rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://cdn.mamamia.com.au/wp/wp-content/uploads/2019/03/09092208/53010652_10158409819532516_4775425951460753408_o-600x800.jpg" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Juniper Wilder</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[whitesmoke] text-center pt-5 pb-5'>
            I suspected my husband was cheating on me. I hired Spyoptron to investigate. I found out he was cheating and taking money from my business to give to a scammer posing as a woman online. Spyoptron saved me from a lot of pain and heartache. Now I can move on with my life. I found out about the cheating when Spyoptron gave me access to his text messages.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Memphis, USA  🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='bg-[#111639] rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i1.sndcdn.com/avatars-RsqzPIxBLr7uiceH-w5TdSQ-t500x500.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Lachlan Freeman</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[whitesmoke] text-center pt-5 pb-5'>
            My iCloud was locked, and I couldn't access my iPhone, MacBook, or iPad. I had a lot of important information on these devices, and I was worried about losing it. I tried to get help from other companies, but no one could unlock my iCloud. Finally, I contacted Spyoptron. They were able to unlock my iCloud quickly and easily. I'm so grateful to Spyoptron for their help. They saved me a lot of stress and worry.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Wales, United Kingdom 🇬🇧 </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
