import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// CSS for the logo animation
 // Replace with your actual CSS file or include the styles in your component

export default function SimpleBackdrop() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 5000);
  }, []);

  return (
    
    <div className=''>
        
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', // Vertically center content
          height: '100vh', // Full viewport height
        }}
        open={open}
        onClick={handleClose}
  
  >
        <div
          style={{
            position: 'relative', // Make the container relative for absolute positioning
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            color="inherit"
            size={120}
            thickness={2}
            style={{
              position: 'absolute', // Position the CircularProgress absolutely
            }}
          />
          <img
  src="optron.png" // Replace with the path to your logo
  alt="Logo"
  className={`logo ${open ? 'loading' : ''}`}
  style={{
    width: '80px', // Adjust the size as needed
    height: 'auto',
    marginTop: '16px', // Adjust the margin-top to position the logo inside the circle
    zIndex: 1, // Ensure the logo is above the CircularProgress
  }}
/>
<p className="pt-8">Spyoptron</p>
        </div>
      </Backdrop>
    </div>
  );
}
