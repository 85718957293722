import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Navbar  from './Components/Navbar';
import Crypto from './Components/Crypto';
import Romance from './Components/Romance';
import ForgottenPasswords from './Components/ForgottenPasswords';
import  SocialMedia from "./Components/SocialMedia";
import Blackmail from "./Components/Blackmail";
import About from './Components/About';
import Contact from "./Components/Contact";


function App() {
  return (
   <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Navbar' element={<Navbar/>}/>
      <Route path='/crypto' element={<Crypto/>}/>
      <Route path='/romance' element={<Romance/>}/>
      <Route path='/ForgottenPasswords' element={<ForgottenPasswords/>}/>
      <Route path='/blackmail' element={<Blackmail/>}/>
      <Route path='/social' element={<SocialMedia/>}/>
      <Route path='/About' element={<About/>}/>
      <Route path='/Contact' element={<Contact/>}/>
      
   </Routes>
  );
}

export default App;
