import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/e4b1v463xyg61.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Theodore Lexington</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            My experience involved a $76,000 scam from a fraudulent investment website, leading to months of futile attempts to 
            recover my funds. Fortunately, I crossed paths with Spyoptron. Upon an initial payment, their adept team promptly 
            and effectively facilitated the restoration of my money, rescuing me from a prolonged and disheartening pursuit.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-10'>Oregon, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://media.licdn.com/dms/image/D4D03AQFfGxOGUtkQ1g/profile-displayphoto-shrink_800_800/0/1688166463380?e=2147483647&v=beta&t=hqW1cDUb-LY038fpkn8v_ixytZFJl8SV5gBY10Yd8XM' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Dashiell Mercer</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            Regrettably, I erroneously transferred my Bitcoins to a fraudulent recovery firm, hoping to recover my lost assets. After enduring a prolonged battle to retrieve my funds, I discovered relief in Spyoptron. With their proficient counsel and a modest initial payment, they swiftly and effectively recovered my lost Bitcoins, preventing any further financial losses for me.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-5'>Kansas, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/EopA1ucW8AA8sZP.jpg:large' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Cassius Winthrop</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            Having mistakenly sent my $46,000 coins to a scammer, I encountered a dire situation. Spyoptron became my savior in this predicament. Their proficient team, following an initial payment, promptly retrieved my lost coins, alleviating the distress and anguish caused by the erroneous transaction."
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[15%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[15%]'>Montana, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://miro.medium.com/v2/resize:fit:480/0*DV5aoHbu4IybaZlS.' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Octavia Everly</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            After investing over $54,000 on a scam website, I was unable to withdraw my profits. However, Spyoptron swiftly restored my funds and took down the fraudulent website. Their expertise saved my investment and put an end to the scam, providing both financial recovery and peace of mind.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[15%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[15%]'>Vermont, Canada 🇨🇦  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://static.independent.co.uk/s3fs-public/thumbnails/image/2014/03/19/20/kim-marsh.jpg?width=1200&height=1200&fit=crop' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Seraphina Hollis</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            Losing my $60,000 in coins to a scammer left me in a difficult position. However, Spyoptron emerged as my beacon of hope. Their adept team, with a nominal upfront payment, efficiently recovered my lost coins, sparing me from the distress and turmoil caused by the fraudulent incident.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[14%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[14%]'>Manitoba , Canada 🇨🇦 </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
