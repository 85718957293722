import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5 mb-10'>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://static-cdn.jtvnw.net/jtv_user_pictures/7be68a11-9eb3-4d35-9a36-ef75870956e7-profile_image-300x300.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Christopher Smith</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            My Instagram account got hacked and disabled, causing me a lot of distress. Spyoptron came to my rescue, 
                    swiftly recovering my account and providing valuable security guidance. Their quick response and expertise made all 
                    the difference. I'm incredibly grateful for their exceptional service. If you have any more requests or need further adjustments, feel free to ask.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-4'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-4'>California, USA  🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[hite] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNjO1FLSX9h1JNlJU9UwTo8X0j9NRSvz_d9O6B5REju3BwjvCkdmWQP9abpBgL6MVtnCs&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Michael Brown</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            My Snapchat account had been disabled for months, and I was desperately trying to recover it as it held countless precious memories. That's when I discovered Spyoptron. They not only effortlessly restored my account but also helped me enhance its security. I'm incredibly grateful to have found this remarkable team.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[10%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[10%]'>San Francisco, USA  🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos2.spareroom.co.uk/images/flatshare/listings/large/49/47/49477486.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '> Madison Williams</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[5%] pb-5 pr-1 pl-1'>
          For months, my husband had been deceiving me, diverting money to his secret girlfriend. My suspicions grew, but I 
                    needed concrete evidence. That's when a friend recommended Spyoptron. They Helped Hacked into hes Account 
                     and now I 
                    possess undeniable proof of his infidelity and financial misconduct. Thanks to Spyoptron, he's repaying 
                    all the money he owed me.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[5%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[5%]'>Madrid, Spain 🇪🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/EjRnWbHX0AIb-HO.jpg ' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Stephan Rodriguez</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
            My Facebook account fell victim to hacking, leaving an unknown individual misusing it, soliciting money from my friends. Desperate for a solution, I reached out to Spyoptron. Their team swiftly restored my account and fortified it with added protection. Now, no cyber threat can come close to it.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[5%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[5%]'>Oregon, USA 🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md Shadow__x'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/cb281daa21bc036868a52fe76634fa82.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[#e6e4e4] font-bold pt-2 '>Olivia Campbell</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
          <p className='text-[#e6e4e4] text-center pt-[12%] pb-5 pr-1 pl-1'>
          My Instagram account  of 789k Followers was disabled for months, depriving me of countless cherished memories. Despite my efforts, I couldn't recover it. Then, I discovered Spyoptron, and they effortlessly restored my account. Not only that, but they also bolstered my account's security. I'm grateful to have found this remarkable team.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1%]'>
           <i class="ri-map-pin-2-line text-white"></i>
           </div>
            <p className='text-[#e6e4e4] pt-[1%]'>Scotland, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
