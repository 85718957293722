import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import InstagramIcon from '@mui/icons-material/Instagram';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TtyIcon from '@mui/icons-material/Tty';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import TelegramIcon from '@mui/icons-material/Telegram';

const actions = [
  { icon: <InstagramIcon />, name: 'Instagram', url: 'https://www.instagram.com/scripttron/?utm_source=ig_web_button_share_sheet' },
  { icon: <WhatsAppIcon />, name: 'WhatsApp', url: 'https://wa.me/+18722651393'},
  { icon: <TelegramIcon  />, name: 'Telegram', url: 'https://t.me/spyoptron8' },
  { icon: <AttachEmailIcon />, name: 'Email', url: 'mailto:Spyoptron@gmail.com' },
];

export default function BasicSpeedDial() {
  const handleActionClick = (url) => {
    if (url.startsWith('mailto:')) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{
        position: 'fixed',
        bottom: 16,
        left: 16,
      }}
      icon={<TtyIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleActionClick(action.url)}
        />
      ))}
    </SpeedDial>
  );
}
