import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import PasswordReview from "./hooks/PasswordReview";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const ForgottenPasswords = () => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
      
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };
      
      const fabWhiteStyle = {
          color: 'common.white',
          bgcolor: 'primary',
          '&:hover': {
            color: '#29d090', // Change the color on hover
            bgcolor: '#007FFF',
          },
        };
      
     
        const [value, setValue] = React.useState(0);
        const isMobile = useMediaQuery('(max-width: 600px)');
      
        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
      
        const transitionDuration = 500;
      
        const fabs = [
          {
            color: 'primary',
            sx: fabStyle,
            icon: <StarIcon />, // Icon for Excellence
            label: 'Excellence',
          },
          {
            color: 'primary',
            sx: fabStyle,
            icon: <SecurityIcon />, // Icon for Integrity
            label: 'Integrity',
          },
          {
            color: 'primary',
            sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
            icon: <GroupIcon />, // Icon for Teamwork
            label: 'Teamwork',
          },
        ];
  return (
   <>
   <Progress/>
   <div>
   <section class="Passwords overflow-hidden">
            <div className='relative'>
                <Navbar/>
            </div>
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[2.5rem] text-[2rem]">
                        Forgotten Passwords Recovery 
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
   </div>
   
   <div className='bg-black h-fit'>
            <div className='mx-auto overflow-hidden md:max-w-7xl pt-10'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 shadow__box px-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[1%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-white'>
                                Forgotten Passwords? Whether it's a misplaced wallet or an elusive BIP38 password, Spyoptron's dedicated team of highly skilled password recovery engineers is poised to provide expert assistance. Armed with established and sophisticated techniques, we specialize in the meticulous retrieval or decryption of cryptocurrency wallet passwords. It's worth noting that in numerous instances, our adept team has successfully restored access within a remarkably short span of days.
                                </p>
                            </div> <br />

                            <div>
                                <p className='text-white'>
                                Once we secure your approval, the meticulous data recovery process kicks into gear, tailored to the specific service level you've chosen. Our state-of-the-art secure portal offers you more than just a glimpse into the progress of your recovery – it provides real-time visibility. Additionally, you'll have access to a detailed list of files that are poised for restoration.
                                When it comes to password recovery, trust is paramount. Rest assured, Spyoptron is your reliable partner for swift and secure solutions. Count on us to seamlessly restore access to your vital data, ensuring your peace of mind throughout the process.
                                </p>
                            </div>
                        </div>
                        <div className='bg-reen-600 h-fit'>
                            <div className='flex justify-center items-center xl:pt-5 pt-0'>
                                <img src="https://t3.ftcdn.net/jpg/01/37/26/32/360_F_137263233_9sEpw5hHFZnwkzX3lULuH56Xmw8tukwt.jpg" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>
                   
                </div>
            </div>



            <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'>Services <i class="ri-sparkling-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Key Services </p>
                </div>



                <div className="mx-auto overflow-hidden  md:max-w-7xl text-white">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="reset-passwordx.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">12-word passphrase<br/> Password Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="social-strategy.png" alt="" className="w-[50px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Social Media<br/> Password Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="blockchain.png" alt="" className="w-[50px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Blockchain<br/>Password Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="technology.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Phone & Dvice<br/>Password Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="e-payment.png" alt="" className="w-[55px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Banking & Financial <br/> Password recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="mail-account.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Email Accounts <br/> Password Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="password-manager.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Password Managers <br/> Password Recovery</p>
                        </div>
                    </motion.div>
                    
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shieldo.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div> 

            <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> Core Values <i class="ri-medal-2-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Why Choose Us</p>
                </div>

                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-gree-600 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://www.ust.com/content/dam/ust/images/people/team-in-control-center-people.jpeg?auto=webp,smallest&quality=80" alt="" />
                            </div>
                        </div>
                        <div className='bg-green600 h-fit'>
                        <div className='bg-[reen] h-fit'>
                            <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
                                <CssBaseline />
                                    <Box
                                        sx={{
                                            bgcolor: '#181a29', // Change the background color here
                                            width: '100%',
                                            position: 'relative',
                                            minHeight: 200,
                                            marginLeft: 0,
                                             marginRight: 0,
                                        }}
                                    >
                                    <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="#007FFF" // Change the indicator color here
                                            textColor="#29d090"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
                                        >
                                            <Tab label="Specialization" {...a11yProps(0)} />
                                            <Tab label="Solutions" {...a11yProps(1)} />
                                            <Tab label="Security" {...a11yProps(2)} />
                                      </Tabs>
                                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Expertise and Specialization</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        Spyoptron stands out for its dedicated team of highly skilled password recovery engineers with specialized expertise. We possess in-depth knowledge and experience in recovering passwords for various applications, including complex cryptocurrency wallets. Our team leverages proven techniques, ensuring a high success rate in restoring access to vital accounts and data.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Efficiency and Swift Solutions</p>
                        </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        Time is of the essence when it comes to password recovery. Spyoptron is committed to providing swift and efficient solutions. Our streamlined recovery processes, coupled with advanced tools and methodologies, enable us to expedite the retrieval of passwords. In numerous instances, we have successfully restored access within a remarkably short timeframe, minimizing disruption and ensuring a prompt resolution for our clients.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div>
                            <p className='font-bold text-[#C0C2CD]'>Transparent Communication and Security</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        At Spyoptron, transparency and security are paramount. Once we obtain your approval, our secure portal provides you with real-time visibility into the status of your recovery. You can track progress and access a comprehensive list of recoverable files. Our commitment to open communication ensures that you are informed throughout the recovery process, instilling confidence in the security and integrity of our services.
                        </div>
                    </TabPanel>
                        {fabs.map((fab, index) => (
                            <Zoom
                                key={fab.color}
                                in={value === index}
                                timeout={transitionDuration}
                                style={{
                                transitionDelay: `${value === index ? transitionDuration : 0}ms`,
                            }}
                                unmountOnExit
                            >
                                <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                                </Fab>
                            </Zoom>
                            ))}
                    </Box>
                </Container>
                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

        <div className='mx-auto overflow-hidden max-w-7xl'>
                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> <i class="ri-double-quotes-l text-[#007FFF]"></i> Testimonies <i class="ri-double-quotes-r text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Reviews </p>
                </div>
            <PasswordReview/>
        </div>


        <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>



              <Footer/>

   </div>
   </>
  )
}

export default ForgottenPasswords;