import React from 'react';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom"
import Navbar from './Navbar';
import { useForm } from "react-hook-form";
import {FaUserCircle} from "react-icons/fa"
import {LiaFlagUsaSolid} from 'react-icons/lia'
import {AiOutlineMail} from "react-icons/ai"
import {TbMessage2Bolt} from "react-icons/tb"
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";

const Contact = () => {
    const {
        register,
        trigger,
        formState: { errors },
      } = useForm();
    
      const onSubmit = async (e) => {
        console.log("~ e", e);
        const isValid = await trigger();
        if (!isValid) {
          e.preventDefault();
        }
      };
  return (
   <>
   <Progress/>
   <div className="box1 overflow-hidden">
            <section class="Contact">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
               <div className='relative'>
               <Navbar/>
               </div>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[4rem] text-[2rem]">
                           Contact us
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
   
   <div className='bg-[black] h-fit'>
        <div className='mx-auto overflow-hidden max-w-7xl'>
            <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className='bg-reen-600 h-fit shadow__box'>
                <div className="container mx-auto p-4">
      <form className="max-w-lg mx-auto" 
      action='https://formsubmit.co/8fe4277aba240d14a24c179b1a9887b8'
      onSubmit={onSubmit}
      method='POST'>
        <div className="mb-4 relative">
           <FaUserCircle className="w-5 h-5 absolute left-3 top-3 text-gray-400 " /> 
          <input
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Name"
            className="border border-[#007FFF] text-white bg-black rounded  pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2  focus:ring-[#007FFF]"
            {...register("Name", {
              required: true,
              maxLength: 100,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-2">
                    {errors.email.type === "required" && "Your Name Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}
        <div className="mb-4 relative">
           <LiaFlagUsaSolid className="w-5 h-5 absolute left-3 top-3 text-gray-400" /> 
          <input
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Country"
            className="border border-[#007FFF] text-white bg-black rounded  pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2  focus:ring-[#007FFF]"
            {...register("Country", {
              required: true,
              maxLength: 2000,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Country Is Required."}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}
        <div className="mb-4 relative">
           <AiOutlineMail className="w-5 h-5 absolute left-3 top-3 text-gray-400" /> 
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            className="border border-[#007FFF] text-white bg-black rounded  pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2  focus:ring-[#007FFF]"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}/>
        </div>
        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                    {errors.email.type === "required" && "Your Email Is Required."}
                    {errors.email.type === "pattern" && "Invalid email address."}
                  </p>
                  </div>
                    )}

      <select id="my-select"
      {...register("selection", {
        required: true,
        
      })} className='border border-[#007FFF] text-white bg-black rounded  pl-1 pr-3 py-2 w-full focus:outline-none focus:ring-2  focus:ring-[#007FFF] mb-5'>
        <option value="" className='text-[gray]'>Select Service</option>
        <option value="Restore Lost Crypto">Restore Lost Crypto</option>
        <option value="Romance Scam Recovery">Romance Scam Recovery</option>
        <option value="Pig Butchering">Romance and Pig Butchering scam Recovery</option>
        <option value="Bitcoin Recovery">Bitcoin Recovery</option>
        <option value="Wallet Tracking">Wallet Tracking</option>
        <option value="Spy">Spying</option>
        <option value="Account Activation">Account Activation</option>
        <option value="BlackMail/Extortion">BlackMail/Extortion</option>
        <option value="USDT Recovery/Hack">USDT Recovery/Hack</option>
        <option value="Gmail Recovery/Hack">Gmail Recovery/Hack</option>
        <option value="Forgotten Passwords Recovery">Forgotten Passwords Recovery</option>
        <option value="Password Recovery">Rug Pull Scam Recovery</option>
        <option value="Background Check Removal">Background Check Removal</option>
        <option value="Wallet Drainer Recovery">Wallet Drainer Recovery</option>
        <option value="Tracking">Tracking</option>
        <option value="Phoen Unlock">Phone Unlock</option>
        <option value="Narcissist Victims Support Health Line">Narcissist Victims Support Health Line</option>
      </select>
                    
        
        <div className="mb-4 relative ">
         <TbMessage2Bolt className="w-5 h-5 absolute left-3 top-3 text-gray-400" /> 
          <textarea
            id="situation"
            name="situation"
            placeholder="Describe Your Situation"
            className="border border-[#007FFF] text-white bg-black rounded  pl-10 pr-3 py-2 w-full focus:outline-none focus:ring-2  focus:ring-[#007FFF]"
            rows="4"
            {...register("Message", {
              required: true,
              maxLength: 2000,
            })}></textarea>

        {errors.email && (
                  <div className='flex  justify-center mt-2'>
                  <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white ">
                    {errors.email.type === "required" && "Message is Required"}
                    {/* {errors.email.type === "pattern" && "Invalid email address."} */}
                  </p>
                  </div>
                    )}
        </div>
        
        <button
          type="submit"
          className="bg-[#007FFF] text-white px-4 py-2 rounded"
        >
          Submit
        </button>
      </form>
        </div>

                </div>
                <div className='bggreen-600 h-fit'>
                <div className='flex justify-center'>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53089383.18714171!2d-138.06823843517054!3d35.69377179964879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sng!4v1693352094557!5m2!1sen!2sng"
                      width="600"
                      height="450"
                      title="Google Maps"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
            </div>
        </div>
   </div>
   
   <Footer/>
   </>
  )
}

export default Contact