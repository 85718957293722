import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import RugPullReview from "./hooks/RugPullReview";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const RugPull = () => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
      
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };
      
      const fabWhiteStyle = {
          color: 'common.white',
          bgcolor: 'primary',
          '&:hover': {
            color: '#29d090', // Change the color on hover
            bgcolor: '#007FFF',
          },
        };
      
     
        const [value, setValue] = React.useState(0);
        const isMobile = useMediaQuery('(max-width: 600px)');
      
        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
      
        const transitionDuration = 500;
      
        const fabs = [
          {
            color: 'primary',
            sx: fabStyle,
            icon: <StarIcon />, // Icon for Excellence
            label: 'Excellence',
          },
          {
            color: 'primary',
            sx: fabStyle,
            icon: <SecurityIcon />, // Icon for Integrity
            label: 'Integrity',
          },
          {
            color: 'primary',
            sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
            icon: <GroupIcon />, // Icon for Teamwork
            label: 'Teamwork',
          },
        ];
  return (
   <>
   <Progress/>
   <div>
   <section class="Rug overflow-hidden">
            <div className='relative'>
                <Navbar/>
            </div>
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[2.5rem] text-[2rem]">
                    Social Media Spy & Recovery
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
   </div>
   
   <div className='bg-black h-fit'>
            <div className='mx-auto overflow-hidden md:max-w-7xl pt-10'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 shadow__box px-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[1%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-white'>
                                Amidst the expansive digital domain, Spyoptron emerge as restoration specialists adept at reclaiming elusive accounts. Spanning Instagram, Facebook, TikTok, Twitter, SnapChat and beyond, our unmatched prowess in account recovery sets us in a league of our own. Irrespective of the intricate convolutions or multifaceted nuances of platforms, we navigate the trajectory to retrieval seamlessly. Our proficient cohort comprehends the inner machinations of virtual realms, fortified with sophisticated methodologies to swiftly reinstate your access and resurrect your virtual presence.
                                </p>
                            </div> <br />

                            <div>
                                <p className='text-white'>
                                Place your confidence in us for restitution. Armed with unyielding determination, we deploy a diverse array of strategies and resources, steadfast in our commitment to recovering what rightfully belongs to you. Together, we will unravel the intricate webs of deception, not only restoring your financial security but also renewing your faith in humanity.
                                </p>
                            </div>
                        </div>
                        <div className='bg-reen-600 h-fit'>
                            <div className='flex justify-center items-center xl:pt-5 pt-0'>
                                <img src="https://img.freepik.com/premium-photo/businessman-working-with-modern-devices-digital-computer-phone-business-people-are-using-innovative-technology_78773-377.jpg" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>
                   
                </div>
            </div>



            <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'>Services <i class="ri-sparkling-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Key Services </p>
                </div>



                <div className="mx-auto overflow-hidden  md:max-w-7xl text-white">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="https://showmechanism.com/bullhorn.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">All Social Media<br/>Hack/Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="https://showmechanism.com/gmail0.png" alt="" className="w-[50px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Gmail Recovery/<br/>Hack</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="https://showmechanism.com/comment.png" alt="" className="w-[50px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Text Message <br/>Hack/Spy</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="https://showmechanism.com/sim-card0.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Sim Card <br/>Cloning</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="https://showmechanism.com/suspended.png" alt="" className="w-[55px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Suspended Account<br/> recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="assets.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Liquidity Pulls <br/> Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="social-engineering.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Social Engineering <br/> Recovery</p>
                        </div>
                    </motion.div>
                    
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shieldo.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div> 

            <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> Core Values <i class="ri-medal-2-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Why Choose Us</p>
                </div>

                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-gree-600 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://media.istockphoto.com/id/1075599562/photo/programmer-working-with-program-code.jpg?s=612x612&w=0&k=20&c=n3Vw5SMbMCWW1YGG6lnTfrwndNQ8B_R4Vw-BN7LkqpA=" alt="" />
                            </div>
                        </div>
                        <div className='bg-green600 h-fit'>
                        <div className='bg-[reen] h-fit'>
                            <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
                                <CssBaseline />
                                    <Box
                                        sx={{
                                            bgcolor: '#181a29', // Change the background color here
                                            width: '100%',
                                            position: 'relative',
                                            minHeight: 200,
                                            marginLeft: 0,
                                             marginRight: 0,
                                        }}
                                    >
                                    <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="#007FFF" // Change the indicator color here
                                            textColor="#29d090"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
                                        >
                                            <Tab label="Audits" {...a11yProps(0)} />
                                            <Tab label="Recoveries" {...a11yProps(1)} />
                                            <Tab label="Approach" {...a11yProps(2)} />
                                      </Tabs>
                                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Expertise in Smart Contract Audits</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        Spyoptron boasts a team of experts proficient in conducting comprehensive smart contract audits. Our deep understanding of blockchain technology allows us to identify vulnerabilities and deceptive tactics employed in rug pull scams. Clients can trust our specialized knowledge to navigate the complexities of smart contracts, enabling a thorough recovery process.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Proven Track Record of Successful Recoveries</p>
                        </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        With a history of successful recoveries, Spyoptron has demonstrated its ability to reclaim assets for clients affected by rug pull scams. Our proven track record speaks to the effectiveness of our recovery methods, instilling confidence in clients that we possess the skills and experience needed to restore their financial assets.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div>
                            <p className='font-bold text-[#C0C2CD]'>Client-Centric Approach and Support</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                        At Spyoptron, we prioritize our clients throughout the recovery journey. Our client-centric approach involves transparent communication, personalized support, and a commitment to guide individuals affected by rug pulls back to financial stability. We understand the emotional and financial toll of such scams and aim to provide not just recovery but also support and reassurance throughout the process.
                        </div>
                    </TabPanel>
                        {fabs.map((fab, index) => (
                            <Zoom
                                key={fab.color}
                                in={value === index}
                                timeout={transitionDuration}
                                style={{
                                transitionDelay: `${value === index ? transitionDuration : 0}ms`,
                            }}
                                unmountOnExit
                            >
                                <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                                </Fab>
                            </Zoom>
                            ))}
                    </Box>
                </Container>
                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

        <div className='mx-auto overflow-hidden max-w-7xl'>
                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> <i class="ri-double-quotes-l text-[#007FFF]"></i> Testimonies <i class="ri-double-quotes-r text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center'>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Top Reviews </p>
                </div>
            <RugPullReview/>
        </div>


        <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>



              <Footer/>

   </div>
   </>
  )
}

export default RugPull;